import React, { useRef, useState } from "react";
import "./styles.css";
import ReactCardFlip from "react-card-flip";

const BeforeAfter = ({ image1, image2, during }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const front = useRef(null);
  const back = useRef(null);
  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <ReactCardFlip isFlipped={isFlipped} infinite={false}>
      <div
        ref={front}
        className={"card"}
        onClick={handleClick}
        style={{
          backgroundImage: `url('${image1}')`,
        }}
      >
        <h1>BEFORE</h1>
      </div>

      <div
        ref={back}
        className={"card"}
        onClick={handleClick}
        style={
          !back.current
            ? {}
            : {
                backgroundImage: `url('${image2}')`,
                height: back.current.style.width,
              }
        }
      >
        {during ? <h1>DURING!</h1> : <h1>AFTER</h1>}
      </div>
    </ReactCardFlip>
  );
};

export default BeforeAfter;
