import "./App.css";
import { useCallback, useEffect, useRef, useState } from "react";
import botanic from "./img_optm/botanic_edit.jpg";
import lawn from "./img_optm/lawn.png";
import parktown from "./img_optm/parktown_edit.png";
import urban from "./img_optm/urban_edit.jpg";
import decking from "./img_optm/decking_edit.jpg";
import pergola from "./img_optm/pergola_edit.jpg";
import map from "./img_optm/map.jpg";
import logo from "./img/logo.svg";
import logoH from "./img/logo_horiz.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image1 from "./img/image1.jpeg";
import image2 from "./img/image2.jpeg";
import gravel1 from "./img/gravelbefore.jpeg";
import gravel2 from "./img/gravelafter.jpeg";
import shed1 from "./img/shed2.jpeg";
import shed2 from "./img/shed1.jpeg";
import gravel3 from "./img/gravel3.jpeg";
import gravel4 from "./img/gravel4.jpeg";
import trikeSummer from "./img/trike_summer.webp";
import flowers from "./img/flowers_edit.png";
import { ContactForm } from "./components/ContactForm";
import { OneDayMakeoverSection } from "./components/OneDayMakeoverSection";
import {ACCEPTING_CLIENTS, DISABLE_CONTACT} from "./constants";

function App() {
  const beforeAfterPics = [
    { image1: image1, image2: image2 },
    { image1: gravel1, image2: gravel2 },
    { image1: shed1, image2: shed2 },
    { image1: gravel3, image2: gravel4, during: true },
  ];

  const app = useRef(null);
  const formWrapper = useRef(null);
  const contactForm = useRef(null);
  const [welcome, setWelcome] = useState(true);
  const [formState, setFormState] = useState(false);

  const logoStyle = welcome ? { display: "block" } : { display: "none" };

  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);

  const welcomeButtonTimeout = useRef(undefined);

  const welcomeClick = () => {
    welcomeButtonTimeout.current = setTimeout(() => setWelcome(false), 300);
  };

  const initBGIntersectionObserver = useCallback(() => {
    const options = {
      threshold: 0.3,
    };

    const bg_sections = [
      section1,
      section2,
      section3,
      section4,
      section5,
      section6,
      section7,
      section8,
      section9,
      section10,
    ];

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("trans", entry.isIntersecting);
      });
    }, options);

    bg_sections.forEach((section) => {
      observer.observe(section.current);
    });
  }, []);

  const inViewportIntersectionObserver = useCallback(() => {
    const options = {
      threshold: 0.3,
    };
    const inViewport = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
      });
    };
    const vpObserver = new IntersectionObserver(inViewport, options);
    const ELs_inViewport = document.querySelectorAll("[data-inviewport]");
    ELs_inViewport.forEach((EL) => {
      console.log(EL);
      vpObserver.observe(EL);
    });
  }, []);

  useEffect(() => {
    initBGIntersectionObserver();
    inViewportIntersectionObserver();
  }, [initBGIntersectionObserver, inViewportIntersectionObserver]);

  const showForm = () => {
    formWrapper.current.style.display = "block";
    contactForm.current.style.transform = "translateX(-50%) translateY(0)";
    formWrapper.current.style.opacity = 1;
    formWrapper.current.style.zIndex = 49;
    document.body.style.overflowX = "hidden";
    setFormState(true);
  };

  const hideForm = () => {
    setFormState(false);
    document.body.style.overflowX = "auto";
    formWrapper.current.style.opacity = 0;
    formWrapper.current.style.zIndex = 0;
    formWrapper.current.style.display = "none";
    contactForm.current.style.transform = "translateX(-50%) translateY(-300%)";
  };

  const showHideForm = () => {
    switch (formState) {
      default:
        break;
      case false:
        showForm();
        break;
      case true:
        hideForm();
        break;
    }
  };

  return (
    <div className="App" ref={app}>
      <nav className="navbar">
        <div className="container flex flex-center space-between w-full">
          <div className="brand" onClick={() => window.location.reload()}>
            Park Town Gardener
          </div>
          <ul className="nav-links">
            <li className="nav-link" onClick={showHideForm}>
              Request a consultation
            </li>
          </ul>
        </div>
      </nav>
      <div className="form-wrapper" ref={formWrapper} onClick={hideForm} />
      <div className="contact-form" ref={contactForm}>
          <ContactForm close={hideForm} acceptingClients={ACCEPTING_CLIENTS} />
      </div>
      <section
        style={{
          backgroundImage: `url(${trikeSummer})`,
          backgroundPosition: "center bottom",
        }}
        className="section section1 top-section"
        ref={section1}
      >
        <object data={logoH} style={logoStyle} className="main-logo">
          <img src={logoH} />
        </object>
        <h1 className="text-xl">Park Town Gardener</h1>
        {welcome ? (
          <>
            <button onClick={welcomeClick} className="btn main-btn">
              Find Out More
            </button>
            <a href="#contact">
              <button className="btn main-btn">Get In Touch</button>
            </a>
          </>
        ) : (
          <>
            <p className="story-section">
              Park Town Gardener's primary gardener has over 10 years of
              experience working in both commercial and domestic gardening
              contexts, and has a rich network of connections enabling us to
              complete your project as affordably as possible.
              <br />
              <br />
              We'll take on your regular gardening chores, or your one-off jobs
              and let you enjoy your garden just how you like it, all year
              round!
            </p>
            <a href="#maintenance">
              <img
                alt="Arrow Down Icon"
                src="https://raw.githubusercontent.com/solodev/scroll-down-anchor/master/images/arrow-down-1.png"
              />
            </a>
          </>
        )}
      </section>

      <section
        id="maintenance"
        style={
          !welcome
            ? { backgroundImage: `url(${parktown})` }
            : { display: "none" }
        }
        className="section section1"
        ref={section2}
      >
        <h1 className="text-xl text-primary">Maintenance</h1>
        <p className="story-section">
          Sometimes gardens can get just a little bit out of control,
          particularly at peak growing times of year and in the Autumn,
          especially in leafy Oxford! In many cases, it just requires a quick
          sweep up and a drop of weed killer, or maybe the patio needs a deep
          power-washing, to get things back on track. Park Town Gardener will
          get your outdoor space spic and span and keep your garden presentable
          and operational, no matter the season.
        </p>
      </section>
      <section
        style={
          !welcome ? { backgroundImage: `url(${lawn})` } : { display: "none" }
        }
        className="section section2"
        ref={section3}
      >
        <h1 className="text-primary text-xl">Lawn Care</h1>
        <p className="story-section">
          Getting that immaculate looking lawn can be an illusive task! Luckily,
          Park Town Gardener is here to help you add the professional touch to
          your lawn, and get that pristine finish on your garden and property.
          Lawn mowing, trimming and edging services, moss and pest control,
          fresh turf provided for areas large or small, Park Town Gardner,
          Oxford, has got you covered, whatever your lawn care needs might be!
        </p>
      </section>
      <section
        style={
          !welcome
            ? { backgroundImage: `url(${decking})` }
            : { display: "none" }
        }
        className="section section1"
        ref={section4}
      >
        <h1 className="text-primary text-xl">Decking</h1>
        <p className="story-section">
          Decking harnesses the potential of sloping or uneven garden terrain.
          Additionally, elevated or multi-level decks can be created on level
          ground to establish new dimensions to your garden, giving it a unique
          look. Park Town Gardener, Oxford, will ensure that we deliver
          perfection on your decking and/or other hard landscaping projects.
        </p>
      </section>
      <section
        style={
          !welcome
            ? { backgroundImage: `url(${pergola})` }
            : { display: "none" }
        }
        className="section section2"
        ref={section5}
      >
        <h1 className="text-xl text-primary">Fencing</h1>
        <p className="story-section">
          Not only can new fencing provide you with privacy and security, but it
          also has the ability to completely transform the appearance of your
          outdoor space. Park Town Gardener can erect you a fence for either
          domestic or commercial applications. Ask Park Town Gardener about
          eco-friendly fence treatments, to help your fence last (and stay
          looking good!) for many years to come.
        </p>
      </section>
      <section
        style={
          !welcome ? { backgroundImage: `url(${urban})` } : { display: "none" }
        }
        className="section section1"
        ref={section6}
      >
        <h1 className="text-primary text-xl">Planting / Design</h1>
        <p className="story-section">
          Whatever the nature or dimension of your garden, Park Town Gardener of
          Oxfordshire can get it looking tidy and presentable. Everyone needs a
          bit of greenery in their life and no space is too small. Generally
          easy and pleasurable to maintain, an urban beautification garden
          makeover could be the perfect gift for a friend or relative who's
          outdoor space could do with a spruce up!
        </p>
      </section>
      <section
        style={
          !welcome
            ? {
                padding: "10rem 0",
                background: `linear-gradient(45deg, #1C4514, #172F0A), url("${botanic}") no-repeat`,
                backgroundSize: "cover",
                backgroundBlendMode: "lighten",
              }
            : { display: "none" }
        }
        className="section section1"
        ref={section7}
      >
        <OneDayMakeoverSection beforeAfterPics={beforeAfterPics} />
      </section>
      <section
        style={
          !welcome
            ? {
                backgroundImage: `url("https://www.goodwoodtreesurgery.co.uk/images/dismantle_header_5.jpg")`,
              }
            : { display: "none" }
        }
        className="section section2"
        ref={section8}
      >
        <h1 className="text-primary text-xl">Tree Surgery</h1>
        <p className="story-section">
          PTG partner with <a href="http://future-trees.co.uk">Future Trees</a>{" "}
          to provide a seasoned Tree Surgery and logging service. Often a much
          needed part of a full gardening service. All our organic waste
          products are recycled in our own facility and reused, making us
          completely self-sufficient in compost, bark chippings and other
          materials.
        </p>
      </section>
      <section
        style={{
          backgroundBlendMode: "saturation",
          backgroundImage: `url('${flowers}')`,
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
        className="section section2"
        ref={section9}
        id="info"
      >
        <h1 className="text-primary">
          The <span className="text-xl text-secondary">Green</span> Gardening
          Solution for Oxford
        </h1>
        <p className="story-section">
          In addition to leaving your grass greener than your neighbours', we
          like to think we're the greenest most environmentally-conscious
          service in town.
          <br />
          <br />
          Where possible, Park Town Gardener's core team transport themselves
          and their equipment by pedal power alone!
        </p>
      </section>
      <section
        style={{ backgroundImage: `url(${map})` }}
        className="section section2 map-section"
        ref={section10}
        id="contact"
      >
        <object data={logo} className="map-logo" data-inviewport="fadeIn">
          <img src={logo} />
        </object>
        <p className="story-section">
          If you're situated in Oxford, or the surrounding area, and you need a
          professional local gardening service, tap below to get in touch!
        </p>
        <button className="btn main-btn" onClick={showHideForm}>
          Get in touch
        </button>
      </section>
      <footer>
        <div className="container flex flex-center space-between">
          <div className="brand">Park Town Gardener</div>
          <div className="w-full">
            <ul>
              <li>
                <a href="#info">About</a>
              </li>
              <li>
                <a href="#" onClick={showHideForm}>
                  Enquire
                </a>
              </li>
              <li>
                <a href="#top">Back to top</a>
              </li>
            </ul>
          </div>
          <div className="flex flex-center space-between">
            <a href="https://facebook.com/parktowngardener">
              <FontAwesomeIcon icon={["fab", "facebook"]} className="fa-2x" />
            </a>
            <a href="https://www.instagram.com/parktowngardener/">
              <FontAwesomeIcon icon={["fab", "instagram"]} className="fa-2x" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
