import * as React from "react";
import {ADD, useToastContext} from "../contexts/ToastContext";
import {CONTACT_URL, DISABLE_CONTACT} from "../constants";

export function ContactForm({
                                close,
                                acceptingClients,
                            }: {
    close: () => void;
    acceptingClients: boolean;
}) {
    const {toastDispatch} = useToastContext();

    const [name, setName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [services, setServices] = React.useState<string[]>([]);

    const timeout = React.useRef<ReturnType<typeof setTimeout> | undefined>();

    function buildEmailNotification(): string {
        let email = "You have a new website enquiry!";
        email += `\n\nName: ${name}`
        email += `\nEmail: ${email}`
        email += `\n\nExpressed interest in: ${services.join(", ")}`
        return email;
    }

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();
        fetch(CONTACT_URL, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
            body: JSON.stringify({subject: "Website Enquiry", body: buildEmailNotification()}),
        })
            .then((response) =>
                response.json().then(() => {
                    if (response.status === 200) {
                        toastDispatch({
                            type: ADD,
                            payload: {
                                content: () => <div>Success! Your enquiry has been sent.</div>,
                                type: "success",
                            },
                        });
                        setName("");
                        setEmail("");
                        setServices([]);
                        timeout.current = setTimeout(close, 300);
                    } else {
                        toastDispatch({
                            type: ADD,
                            payload: {
                                content: () => <div>Something went wrong. Please try again later.</div>,
                                type: "danger",
                            },
                        });
                    }
                })
            )
            .catch((e) => {
                toastDispatch({
                    type: ADD,
                    payload: {
                        content: () => <div>Something went wrong. Please try again later.</div>,
                        type: "danger",
                    },
                });
            });
    }

    React.useEffect(() => {
        return () => clearTimeout(timeout.current as NodeJS.Timeout);
    }, []);

    function handleServicesChange(event: React.ChangeEvent) {
        const target = event.target as HTMLInputElement;
        if (!services.includes(target.name) && target.checked) {
            setServices([...services, target.name]);
        } else if (services.includes(target.name) && !target.checked) {
            setServices(services.filter((service) => service !== target.name));
        }
    }

    return (DISABLE_CONTACT ? <div>Unfortunately, due to high demand, we are currently unable to take on any new clients.</div> :
            <form onSubmit={handleSubmit}>
                <h1 className="form-heading">Get in Touch</h1>
                {!acceptingClients && (
                    <>
                        <p style={{maxWidth: "50ch", margin: "1rem 0"}}>
                            We regret to inform you that Park Town Gardener is currently unable
                            to take on new clients due to high demand. If you would like to join
                            the waiting list, please provide your details below, and we will get
                            back to you when space becomes available.
                        </p>
                    </>
                )}
                <br/>
                <div className="name-email-inputs">
                    <label>
                        <input
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="name"
                            placeholder="Your name"
                        />
                    </label>
                    <label>
                        <input
                            required
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            name="email"
                            placeholder="Your email address"
                        />
                    </label>
                </div>
                <legend>Interested in:</legend>
                <div className="service-radio-group">
                    {[
                        "Maintenance/Upkeep",
                        "Landscaping",
                        "Planting/Design",
                        "Event Preparation",
                    ].map((item) => (
                        <div className="service-radio-input-group">
                            <input
                                onChange={handleServicesChange}
                                checked={services.includes(item)}
                                id={item + "id"}
                                key={item + "input"}
                                type="checkbox"
                                name={item}
                            />
                            <label htmlFor={item + "id"} key={item + "label"}>
                                {item}
                            </label>
                        </div>
                    ))}
                </div>
                <br/>
                <input
                    type="submit"
                    value="Send"
                    style={{cursor: "pointer", padding: "0.25rem 1rem"}}
                />
            </form>
    );
}
