import * as React from 'react';
import BeforeAfter from "./BeforeAfter"

export function OneDayMakeoverSection({beforeAfterPics}: { beforeAfterPics: { image1: string; image2: string; during?: boolean }[] }) {
  return (
    <>
      <h1 className="text-primary text-xl">One Day Makeover</h1>
      <p style={{zIndex: 100}} className="story-section">Big day planned? Get the garden nice now that we're all
        able to socialise a bit more. It's easier to keep your distance in a nice tidy outdoor space!</p>
      <div className={"before-after-wrapper"}>
        <div className="before-after-pics">{beforeAfterPics.map((set, index) => (
          <BeforeAfter key={'ba' + index} image1={set.image1} image2={set.image2} during={set.during}/>
        ))}</div>
      </div>
    </>
  );
};
